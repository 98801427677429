@font-face {
  font-family: 'OpenSans';
  src: url(../../../../fonts/OpenSans-Regular-webfont.ttf), url(../../../../fonts/OpenSans-Regular.eot);
}
@font-face {
  font-family: 'OpenSansSemibold';
  src: url(../../../../fonts/OpenSans-Semibold.ttf), url(../../../../fonts/OpenSans-Semibold.eot);
}
body {
  background: #fff;
  font-family: 'OpenSans';
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  width: 100%;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
header {
  padding: 50px 80px 0px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
header .lang-selected {
  font-family: 'OpenSansSemibold';
  position: relative;
}
header .lang-options {
  display: none;
  position: absolute;
  padding-top: 10px;
  background: #e9e9e9;
  left: 0;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  line-height: 1.5rem;
}
header .lang-options p {
  margin: 0;
  margin-bottom: 5px;
}
header .lang-options p a {
  text-decoration: none;
  color: #000;
  padding: 2px 22px;
}
header .lang-options p a:hover {
  background-color: #e0e0e0;
}
header .lang-switcher {
  position: relative;
  cursor: pointer;
  font-size: 0.8rem;
  background-color: #ececec;
  padding: 6px 32px 0px 32px;
  border-radius: 5px;
  height: 25px;
}
header .lang-switcher .triangle {
  border-color: transparent transparent #b1b1b1 transparent;
  border-style: solid;
  border-width: 0px 5px 7px 5px;
  height: 0px;
  width: 0px;
  position: absolute;
  right: 10px;
  top: 11px;
  transform: rotate(180deg);
}
header .lang-switcher:hover .lang-options {
  display: block;
}
header .lang-switcher:hover .triangle {
  transform: rotate(0deg);
}
main {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-item-align: center;
  align-self: center;
}
main h2 {
  font-family: 'OpenSansSemibold';
  text-align: center;
  font-size: 20px;
}
main form .inut-container {
  position: relative;
}
main form .inut-container:before {
  position: absolute;
  top: 0px;
  left: 0px;
  content: '';
  width: 60px;
  height: 60px;
  z-index: 2;
  background-repeat: no-repeat;
  background-position: center;
}
main form .inut-container.nombre:before {
  background-image: url(../../../../images/auth//nombre.svg);
}
main form .inut-container.email:before {
  background-image: url(../../../../images/auth//email.svg);
}
main form .inut-container.password:before {
  background-image: url(../../../../images/auth//password.svg);
}
main form .inut-container.phone:before {
  background-image: url(../../../../images/auth//phone-call.svg);
}
main form input {
  height: 60px;
  width: 335px;
  border: none;
  background: #ececec;
  margin-bottom: 10px;
  border-radius: 5px;
  padding-left: 65px;
}
main form input[type="submit"] {
  width: 400px;
  background-color: #47B5FF;
  color: #fff;
  padding-left: 0px;
  cursor: pointer;
  font-size: 1rem;
}
main form .checkbox input[type="checkbox"] {
  opacity: 0;
  width: 0px;
  height: 0px;
  margin-bottom: 20px;
}
main form .checkbox label {
  position: relative;
  display: inline-block;
  padding-left: 50px;
  line-height: 35px;
}
main form .checkbox label a {
  font-family: 'OpenSansSemibold';
  color: #000;
}
main form .checkbox label::before,
main form .checkbox label::after {
  position: absolute;
  content: "";
  display: inline-block;
}
main form .checkbox label::before {
  height: 24px;
  width: 24px;
  border: 2px solid #ccc;
  border-radius: 5px;
  left: 0px;
  top: 3px;
  cursor: pointer;
}
main form .checkbox label::after {
  height: 6px;
  width: 13px;
  border-left: 3px solid;
  border-bottom: 3px solid;
  transform: rotate(-45deg);
  left: 6px;
  top: 10px;
  color: #47B5FF;
}
main form .checkbox input[type="checkbox"] + label::after {
  content: none;
}
main form .checkbox input[type="checkbox"]:checked + label::after {
  content: "";
}
main form .g-recaptcha {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 15px;
}
main form .forgot {
  text-align: center;
  margin-top: 20px;
}
main form .forgot a {
  text-decoration: none;
  color: #47B5FF;
}
main .validations {
  color: red;
  font-size: 0.9rem;
  margin-top: 18px;
  text-align: center;
}
main .validations p {
  margin: 0px;
}
main .validations p:first-letter {
  text-transform: capitalize;
}
main .validations p.success {
  color: #00a200;
}
footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 50px;
}
footer a {
  text-decoration: none;
  color: #000;
}
footer b {
  font-family: 'OpenSansSemibold';
}

/*# sourceMappingURL=styles.css.map */
